import React, { useState } from 'react';

//Reveal
import Fade from 'react-reveal/Fade';

//Bootstrap
import { Container, Col, Row } from 'react-bootstrap';

const Contato = () => {
    const [state, setState] = useState({ name: '', email: '' });
    const handleChange = event => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value
        });
    }

    const handlePress = () => {
        fetch(
            '../../../api/send-email',
            {
                method: 'POST',
                body: JSON.stringify(state),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    }

    ///////////////////////
    return (
        <Container className="contato">
            <Row>
                <Col>
                    <div className="contato-container">
                        <div className="contato-infos">
                            <Fade delay={150}>
                                <h3>CONTATO</h3>
                            </Fade>
                            <Fade delay={250}>
                                {/* <a href='tel:+551151812399'><h4>+55 (11) 5181-2399</h4></a> */}
                                <a href='tel:+5511973376252'><h4>+55 (11) 97337-6252</h4></a>
                            </Fade>
                            <Fade delay={450}>
                                <a href="mailto:contato@bfactor.com.br"><h4>contato@bfactor.com.br</h4></a>
                            </Fade>
                            <Fade delay={550}>
                                <a href="https://goo.gl/maps/Us2vywoWxDLYUykU6" target="_blank" rel="noopener noreferrer"><h4>R. da Paz, 1601 - conj 1116 Chácara St. Antonio São Paulo, SP</h4></a>
                            </Fade>
                            <div className="contato-infos-sm">
                                <Fade delay={650}>
                                    <a href="https://www.instagram.com/b.factoreventos/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                    <a href="https://www.facebook.com/B-factor-110907750323488" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></a>
                                </Fade>
                            </div>
                        </div>
                        {/* inputs */}
                        <div className="contato-inputs">
                            <div name="form">
                                <Fade delay={750}>
                                    <input name="name" type="text" placeholder="NOME*" onChange={handleChange} required></input>
                                </Fade>
                                <Fade delay={850}>
                                    <input name="email" type="email" placeholder="E-MAIL*" onChange={handleChange} required></input>
                                </Fade>
                                <Fade delay={950}>
                                    <input name="phone" type="text" placeholder="TELEFONE*" onChange={handleChange} required></input>
                                </Fade>
                                <Fade delay={1050}>
                                    <textarea name="message" type="text" placeholder="MENSAGEM*" onChange={handleChange} required />
                                </Fade>
                                <div className="contato-button-container">
                                    <Fade delay={1150}>
                                        <button name="submit" className="contato-button" onClick={handlePress}>ENVIAR</button>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
};

export default Contato;