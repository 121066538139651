import React from 'react';
import { NavLink } from 'react-router-dom';

//Bootstrap
import { Container, Col, Row } from 'react-bootstrap';

function Falha() {
    return (
        <Container className="agradecimento">
            <Row>
                <Col>
                    <h3> HOUVE UMA FALHA NO ENVIO DA SUA MENSAGEM! </h3>
                    <h4> TENTE NOVAMENTE MAIS TARDE </h4>
                    <NavLink exact to="/">
                        <button className="agradecimento-button">HOME</button>
                    </NavLink>
                </Col>
            </Row>
        </Container>
    )
};

export default Falha;