import React from 'react';
import { NavLink } from 'react-router-dom';

//Bootstrap
import { Container, Col, Row } from 'react-bootstrap';

function Agradecimento() {
    return (
        <Container className="agradecimento">
            <Row>
                <Col>
                    <h3> MENSAGEM ENVIADA! </h3>
                    <h4> EM BREVE ENTRAREMOS EM CONTATO </h4>
                    <NavLink exact to="/">
                        <button className="agradecimento-button">HOME</button>
                    </NavLink>
                </Col>
            </Row>
        </Container>
    )
};

export default Agradecimento;