import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'

import SwiperCore, { Navigation, Pagination } from "swiper";

// carousel images
import carousel1 from '../../Assets/Images/Carousel/1.png'
import carousel2 from '../../Assets/Images/Carousel/2.png'
import carousel3 from '../../Assets/Images/Carousel/3.png'
import carousel4 from '../../Assets/Images/Carousel/4.png'
import carousel5 from '../../Assets/Images/Carousel/5.png'
import carousel6 from '../../Assets/Images/Carousel/6.png'

//Bootstrap
import { Container, Col, Row } from 'react-bootstrap';

SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

function OQueFazemos() {

    const [isMobile, setMobile ] = useState(true)

    useEffect(() => {
        if(window.innerWidth > 992 ){
            setMobile(false)
        }
      });

      return (
        <Container fluid={true} className="o-que-fazemos">
            <Container className="o-que-fazemos-container">
                <Row style={{ display: 'flex', flexDirection: 'row'}} className="o-que-fazemos-row">
                    <Col md={12} lg={5} className="o-que-fazemos-text mb-5">
                        <Fade delay={500}>
                            <h3 className="mb-5">
                                CRIAÇÃO, PLANEJAMENTO <br/> E EXECUÇÃO
                            </h3>
                            <h6>
                                PROJETOS PERSONALIZADOS QUE ATENDEM TODO TIPO DE EVENTO:
                                <br />
                                <br />
                                EQUIPE
                                <br />
                                CENOGRAFIA
                                <br />
                                ALIMENTOS & BEBIDAS
                                <br />
                                SONORIZAÇÃO
                                <br />
                                ARTES VISUAIS
                                <br />
                                TECNOLOGIA
                            </h6>
                        </Fade>
                    </Col>
                    <Col md={12} lg={7} className="o-que-fazemos-swiper mb-5" >
                        <Fade delay={800}>
                            <Swiper
                                spaceBetween={25}
                                slidesPerView='auto'
                                navigation={true}
                                pagination={true}

                                // loop={true}
                            // centeredSlides
                            // onSlideChange={() => console.log("slide change")}
                            // onSwiper={swiper => console.log(swiper)}
                            >
                                <SwiperSlide>
                                    <img src={carousel1} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={carousel2} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={carousel3} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={carousel4} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={carousel5} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={carousel6} />
                                </SwiperSlide>
                            </Swiper>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
};

export default OQueFazemos;