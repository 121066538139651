import React, { Component } from 'react';
//Progress
import Progress from './progress';
import { ReactComponent as Logo } from '../../Assets/Images/logo-branco.svg';

var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

class BG extends Component {

    state = {
        // bgDesktop: "https://res.cloudinary.com/dm5hkqq4y/video/upload/v1606308032/B-FACTOR_-_SEM_TARJA_1080_nrj5ng.mp4",
        bgDesktop: "https://res.cloudinary.com/dgijmk2mg/video/upload/v1607607933/B-FACTOR_-_SEM_TARJA_1080_nj29an.mp4",
        opacity: "1",
        isPaused: true,
        isAutoplay: "false",
        opacityPlayBtn: "1",
        bgStyle: " "
    };

    changeOpacity = () => {
        this.setState({
            opacity: "0"
        });
    }

    playPause = () => {
        var video = this.refs.videoRef;

        if (video.paused) {
            video.play();
            this.setState({
                opacityPlayBtn: "0"
            });
            setTimeout(
                () => this.setState({ isPaused: false }), 500);
        }

        else {
            this.setState({
                isPaused: false
            });
        }
    }

    componentDidMount() {
        var video = this.refs.videoRef;
        this.setState({ isLoading: true, bgStyle: 'video-style' });
        video.play();

        if (video.paused) {
            this.setState({
                isPaused: true
            });
        }
        else {
            this.setState({
                isPaused: false
            });
        }
        
        if (isMobile) {
            this.setState({
                bgDesktop: "https://res.cloudinary.com/dgijmk2mg/video/upload/v1607607933/B-FACTOR_-_SEM_TARJA_1080_nj29an.mp4",
            });
        }

        else {
            this.setState({
                bgDesktop: "https://res.cloudinary.com/dgijmk2mg/video/upload/v1607607933/B-FACTOR_-_SEM_TARJA_1080_nj29an.mp4",
            });
        }
    }

    render() {
        const { isLoading } = this.state;
        const { isPaused } = this.state;

        return (
            <React.Fragment>
                {isLoading && <Progress opacity={this.state.opacity} />}
                <video
                    className={this.state.bgStyle}
                    ref="videoRef"
                    loop
                    muted
                    autoPlay
                    playsInline
                    id="myVideo"
                    preload={'auto'}
                    type="video/mp4"
                    onLoadedData={() => {
                        this.changeOpacity();
                        setTimeout(
                            () => this.setState({
                                isLoading: false }), 500);
                    }}>
                        <source 
                        srcset='https://res.cloudinary.com/spuritika/image/upload/v1635345913/hokup/bfactor/Imagem_2_cpljcu.png' 
                        src={this.state.bgDesktop}></source>
                    </video>

                {/* {isPaused && <div className="play-container" onClick={this.playPause} style={{ opacity: `${this.state.opacityPlayBtn}` }}>
                    <Logo className="play-logo" />
                    <div className="play-button"><i className="fas fa-play"></i></div>
                </div>} */}
            </React.Fragment>

        )
    }
};

export default BG;

