import React, { useState } from 'react';
import ModalVideo from 'react-modal-video'
import Fade from 'react-reveal/Fade';
import thumbnail from '../../Assets/Images/thumbnail.png'

//Bootstrap
import { Container, Col, Row } from 'react-bootstrap';

//Feather
import { Play } from 'react-feather';

function QuemSomos() {

    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <Container className="quem-somos">
                <Row className="quem-somos-container">
                    <Fade delay={150}>
                        <div
                            className='TESTE'
                            style={{
                                height: '100%',
                                width: '100%'
                            }}>
                            <h3 className="mb-5 quem-somos-title">
                                UMA EMPRESA DE EVENTOS 360:
                            </h3>
                            <button onClick={() => setOpen(true)} className="modal-btn mb-5">
                                <img src={thumbnail} />
                                <Play className="play-btn" size={50} />
                            </button>
                            {/* <button onClick={() => setOpen(true)}
                            style={{

                            }}>

                            </button> */}
                        </div>

                    </Fade>

                    {/* <Fade delay={300} >
                            <div>
                                <div className="eventos-container">
                                    <div className="eventos">
                                        <h4>EVENTOS</h4>
                                        <h3>DIAGEO LTDA</h3>
                                    </div>
                                    <div className="eventos">
                                        <h4>PRODUTOR A&B </h4>
                                        <h3>LOLLAPALOOZA</h3>
                                    </div>
                                    <div className="eventos">
                                        <h4>PRODUTOR</h4>
                                        <h3>DGTL</h3>
                                    </div>
                                    <div className="eventos">
                                        <h4>PRODUTOR</h4>
                                        <h3>BRMC</h3>
                                    </div>
                                </div>

                                <div className="eventos-container">
                                    <div className="eventos">
                                        <h4>ARTIST MANAGER</h4>
                                        <h3>RENATO RATIER</h3>
                                    </div>
                                    <div className="eventos">
                                        <h4>PRODUTOR</h4>
                                        <h3>DEDGE FESTIVAL</h3>
                                    </div>
                                    <div className="eventos">
                                        <h4>ARTIST MANAGER</h4>
                                        <h3>GUI BORATTO</h3>
                                    </div>
                                    <div className="eventos">
                                        <h4>PRODUTOR</h4>
                                        <h3>CARNAVAL DERRETE</h3>
                                    </div>
                                </div>
                            </div>
                        </Fade> */}
                </Row>
            </Container>
            <ModalVideo
                channel='vimeo'
                isOpen={isOpen}
                videoId="637835657"
                onClose={() => setOpen(false)}
            />
        </>
    )
};

export default QuemSomos;