import React from 'react';
import Fade from 'react-reveal/Fade';

//Bootstrap
import { Container, Col, Row } from 'react-bootstrap';

function QuemSomos() {
    return (
        <Container className="home">
            <Row className="home-container">
                <Col>
                    <Fade delay={500}>
                        <h1> B FACTOR </h1>
                    </Fade>
                    <Fade delay={800}>
                        <h2> EVENTS PLANNER </h2>
                    </Fade>
                </Col>
            </Row>
        </Container>
    )
};

export default QuemSomos;