import React, { Component } from 'react';

import { ReactComponent as SplashLogo } from '../../Assets/Images/logo-branco.svg';

class Progress extends Component {
    render() {
        return (
            <div className="splashscreen" style={{opacity:`${this.props.opacity}`}}>
                <SplashLogo className="splash-logo"/>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }
}

export default Progress;