import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <p>© {(new Date().getFullYear())} DEV. BY <a target="_blank" rel="noopener noreferrer" href='https://hokup.com.br'>HOKUP</a></p>

                <div className="footer-sm">
                    <a href="https://www.instagram.com/b.factoreventos/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                    <a href="https://www.facebook.com/B-factor-110907750323488" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></a>
                </div>
            </div>
        )
    }
};

export default Footer;