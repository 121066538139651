import './App.scss';
import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

//Header/Footer
import Header from './Components/header/header';
import Footer from './Components/footer/footer';

//BG
import Bg from './Components/background/bg';

//Pages
import Home from './Components/home/home';
import QuemSomos from './Components/quem-somos/quem-somos';
import OQueFazemos from './Components/o-que-fazemos/o-que-fazemos';
import Contato from './Components/contato/contato';
import Obrigado from './Components/contato/agradecimento';
import Falha from './Components/contato/falha';

function App() {
  return (
    <BrowserRouter>
      <Bg/>
      <Header />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/quem-somos' component={QuemSomos} />
        <Route exact path='/o-que-fazemos' component={OQueFazemos} />
        <Route exact path='/contato' component={Contato} />
        <Route exact path='/obrigado' component={Obrigado} />
        <Route exact path='/falha' component={Falha} />
        <Route component={QuemSomos} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
