import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

//Bootstrap
import { Navbar, Nav } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

//Brand Image
import { ReactComponent as Logo } from '../../Assets/Images/logo-branco.svg';

class Header extends Component {
    render() {
        return (
            <Navbar collapseOnSelect expand="lg" className="header">
                <Container fluid>
                    <Navbar.Brand href="/"><Logo className="header-logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link eventKey={2}><NavLink className="nav-active" exact to="/quem-somos">QUEM SOMOS</NavLink></Nav.Link>
                            <Nav.Link eventKey={2}><NavLink className="nav-active" exact to="/o-que-fazemos">O QUE FAZEMOS</NavLink></Nav.Link>
                            <Nav.Link eventKey={2}><NavLink className="nav-active" exact to="/contato">CONTATO</NavLink></Nav.Link>
                            <div className="header-mobile">
                                <div className="header-mobile-sm">
                                    <a href="https://www.instagram.com/b.factoreventos/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                                    <a href="https://www.facebook.com/B-factor-110907750323488" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></a>
                                </div>
                                <p>DEV. BY <a href="https://www.hokup.com.br" target="_blank" rel="noopener noreferrer">HOKUP</a></p>
                            </div>
                        </Nav>
                        <Nav.Link className="header-opacity" eventKey={2}></Nav.Link>
                    </Navbar.Collapse>
                </Container>
            </Navbar >
        )
    }
};

export default Header;